.red-border {
    border-bottom: 1px solid #eb516d;
}

.incomeRow div{
    display: inline;
}

.incomeRow > *[contenteditable="true"] {
    background-color: yellow;
  }

.incomeDescription *[contenteditable="true"]{
background-color: aqua;
}

.charts {
  display: flex;
}

.chart {
  width: 400px;
  height: 420px;
  border: solid;
  border-color: red;
}

.content-page-header {
  margin: 0 0 0px 0;
  display: flex;
  align-items: center;
  padding: 30px 0 0;
  justify-content: space-between;
}

.content-page-header h5 {
  font-size: 24px;
  color: #0F0033;
  font-weight: bold;
  margin: 0;
  line-height: 32px;
  text-transform: uppercase;
}

.content-page-header .list-btn ul {
  list-style: none;
}

.btn.btn-primary {
  background-color: #7539FF;
    border: 1px solid #7539FF;
    box-shadow: inset 0 0 0 0 #fff;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    margin: 0;
    z-index: 1;
    text-transform: initial;
    color: #fff;
    transition: all 0.5s;
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 12px 20px;
    align-items: center;
    text-decoration: none;
}

.btn.btn-primary > span {
  display: flex;
  align-items: center;
}

.btn.btn-primary:hover {
  opacity: 0.8;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.modal-overlay.fade-in {
  opacity: 1;
  visibility: visible;
}

.modal-overlay.fade-out {
  opacity: 0;
  visibility: hidden;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  text-align: center;
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 0.3s, transform 0.3s;
}

.modal.fade-in {
  opacity: 1;
  transform: scale(1);
}

.modal.fade-out {
  opacity: 0;
  transform: scale(0.95);
}

.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  line-height: 1.5;
  appearance: none;
  font-family: "Poppins", sans-serif;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  border: 1px solid #E0E0E0;
  box-shadow: none;
  border-radius: 4px;
  margin: 0;
  padding: 9px 12px;
  font-size: 14px;
  font-weight: 400;
  color: #3F4254;
}

.form-field {
  margin-bottom: 15px;
}

.form-field label {
  font-family: "Poppins", sans-serif;
  margin: 0 0 8px 0;
  font-weight: 500;
  font-size: 14px;
  color: #282828;
  text-align: left;
  display: block;
}

.modal h2 {
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 18px;
  margin-bottom: 0;
}


.modal .modal-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  justify-content: space-between;
}

.d-block {
  display: block;
}

.w-100 {
  width: 100%;
}

.table {
  width: 100%;
  border: 1px solid #e4e4e4;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.table thead tr {
  background-color: #7539ff;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}

.table th,
.table td {
  padding: 12px 15px;
  text-align: center;
}

.table td {
  font-size: 14px;
  font-weight: 400;
}

.table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.table tbody tr:last-of-type {
  border-bottom: 2px solid #ccc;
}

.save-all-main {
  display: flex;
  justify-content: flex-end;
}

.btn-outline-primary {
  background-color: #7539ff;
  border: 1px solid #7539ff;
  padding: 5px 12px;
  border-radius: 8px;
  transition: 0.2s all ease;
}

.btn-outline-primary:hover {
  opacity: 0.8;
  color: #fff;
}

.btn-outline-primary img {
  filter: brightness(0) invert(1);
}

.btn-outline-danger {
  background-color: #cf2121;
  border: 1px solid #cf2121;
  padding: 5px 12px;
  border-radius: 8px;
  transition: 0.2s all ease;
}

.btn-outline-danger:hover {
  opacity: 0.8;
  color: #fff;
}

.btn-outline-danger img {
  filter: brightness(0) invert(1);
}