.login-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 50px 30px;
  margin: 80px auto 0;
  background-color: #fff;
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
  width: 100%;
  flex-direction: column;
  max-width: 450px;
  border-radius: 20px;
}

.login-wrapper-head h3 {
  font-size: 32px;
  text-transform: uppercase;
  line-height: normal;
  margin-bottom: 12px;
}

.mb-4 {
  margin-bottom: 20px;
}

.login-info p {
  font-size: 14px;
  line-height: normal;
  color: #a0a0a0;
}

.login-info p span a {
  color: #121212;
  text-decoration: underline;
}

.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  line-height: 1.5;
  appearance: none;
  font-family: "Poppins", sans-serif;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  border: 1px solid #E0E0E0;
  box-shadow: none;
  border-radius: 4px;
  margin: 0;
  padding: 9px 12px;
  font-size: 14px;
  font-weight: 400;
  color: #3F4254;
}

.form-field {
  margin-bottom: 15px;
}

.form-field label {
  font-family: "Poppins", sans-serif;
  margin: 0 0 8px 0;
  font-weight: 500;
  font-size: 14px;
  color: #282828;
  text-align: left;
  display: block;
}

.mainContainer {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
.titleContainer {
  display: flex;
  flex-direction: column;
  font-size: 64px;
  font-weight: bolder;
  align-items: center;
  justify-content: center;
}

.resultContainer,
.historyItem {
  flex-direction: row;
  display: flex;
  width: 400px;
  align-items: center;
  justify-content: space-between;
}

.historyContainer {
  flex-direction: column;
  display: flex;
  height: 200px;
  align-items: center;
  flex-grow: 5;
  justify-content: flex-start;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 260px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 5px;
}

.inputContainer > .errorLabel {
  color: red;
  font-size: 12px;
}

button {
  cursor: pointer;
  background: transparent;
}
  