* {
    border: 0;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

body,
html {
  font-family: "Poppins", sans-serif;
}
  
  Link {
    color: inherit;
    font-family: "Poppins", sans-serif;
    font-size: inherit;
    text-decoration: none;
  }
  
  
  /*======================================================
                            Navbar
    ======================================================*/
  #navbar {
    background: #fff;
    color: rgb(38, 22, 13);
    width: 100%;
  }
  
  .nav-wrapper {
    margin: auto;
    text-align: center;
    width: 70%;
  } @media(max-width: 768px) {
      .nav-wrapper {
        width: 90%;
      }
    } @media(max-width: 638px) {
        .nav-wrapper {
          width: 100%;
        }
      } 
  
  
  .logo {
    float: left;
    margin-left: 28px;
    font-size: 1.5em;
    height: 60px;
    letter-spacing: 1px;
    text-transform: uppercase;
  } @media(max-width: 768px) {
      .logo {
  /*       margin-left: 5px; */
      }
    }
  
  #navbar ul {
    list-style: none;
    transition: transform 0.5s ease-out;
    -webkit-transition: transform 0.5s ease-out;
  }

  .main-navbar__menu__item__link .account-info {
    display: flex;
    align-items: center;
  }

  .user-content {
    text-align: left;
    margin-left: 5px;
  }

  .user-content .user-details,
  .user-content .user-name {
    font-weight: 500;
  }

  .user-content > span {
    display: block;
  }

  @media(max-width: 640px) {
      #navbar ul {
        display: none;
      }
    }
  
  #navbar li a {
    font-family: "Poppins", sans-serif;
    color: rgb(13, 26, 38);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 0 4px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.5s ease;
  }
  
  #navbar li a:hover {
    color: #7539ff;
    transition: all 1s ease;
  }
  
  /* Animated Bottom Line */
  #navbar li a:before, #navbar li a:after {
    content: '';
    position: absolute;
    width: 0%;
    height: 1px;
    bottom: -1px;
    background: rgb(13, 26, 38);
  }
  
  #navbar li a:before {
    left: 0;
    transition: 0.5s;
  }
  
  #navbar li a:after {
    background: rgb(13, 26, 38);
    right: 0;
    /* transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1); */
  }
  
  #navbar li a:hover:before {
    background: rgb(13, 26, 38);
    width: 100%;
    transition: width 0.5s cubic-bezier((0.22, 0.61, 0.36, 1));
  }
  
  #navbar li a:hover:after {
    background: transparent;
    width: 100%;
    /* transition: 0s; */
  }

  .main-navbar {
    padding: 8px 0;
    box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
  }

  .main-navbar__logo-img {
    width: 50px;
    border-radius: 50%;
  }

  .main-navbar__navbar-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 15px;
  }

  .main-navbar__logo-wrapper .main-navbar__menu__item__link {
    display: flex;
    align-items: center;
  }

  .main-navbar__menu .main-navbar__menu__item {
    line-height: normal;
  }

  .main-navbar__menu__item__link {
    display: flex;
    align-items: center;
    margin-right: 8px;
  }

  .mr-3 {
    margin-right: 6px;
  }

.user-img {
  margin-right: 3px;
  position: relative;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-img img {
  width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
}

.main-navbar__menu {
  display: flex;
  align-items: center;
}

.main-navbar__menu .main-navbar__menu__item {
  display: flex;
  align-items: center;
}

#navbar .subscription-menu li a,
#navbar .subscription-logout li a {
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-transform: none;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #efefef;
}

#navbar .subscription-logout li a {
  border-bottom: 0;
}

#navbar .subscription-menu li a::before, #navbar .subscription-menu li a::after {
  display: none;
}

#navbar .subscription-menu li a:hover {
  background-color: #f5f5f5;
}

.dropdown-menu {
  border: 1px solid #eff2f7;
  border-radius: 0.8rem;
  transform-origin: left top 0;
  background-color: #fff;
  padding: 0;
  box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.08);
  overflow: hidden;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  min-width: 165px;
  z-index: 10;
}

.df {
  display: flex;
}

.ac {
  align-items: center;
}

#navbar .btn.btn-primary {
  padding: 15px 20px !important;
  border-radius: 8px;
  font-size: 14px;
  transition: 0.2s all ease;
  color: #fff;
  font-family: "Poppins", sans-serif;
}

#navbar .btn.btn-primary:hover {
  opacity: 0.9;
  color: #fff;
}

#navbar .btn.btn-primary > span img {
  filter: brightness(0) invert(1);
}

  /*======================================================
                      Mobile Menu Menu Icon
    ======================================================*/
  @media(max-width: 640px) {
    .menuIcon {
      cursor: pointer;
      display: block;
      position: fixed;
      right: 15px;
      top: 20px;
      height: 23px;
      width: 27px;
      z-index: 12;
    }
  
    /* Icon Bars */
    .icon-bars {
      background: rgb(13, 26, 38);
      position: absolute;
      left: 1px;
      top: 45%;
      height: 2px;
      width: 20px;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    } 
  
    .icon-bars::before {
      background: rgb(13, 26, 38);
      content: '';
      position: absolute;
      left: 0;
      top: -8px;
      height: 2px;
      width: 20px;
  /*     -webkit-transition: top 0.2s ease 0.3s;
      transition: top 0.2s ease 0.3s; */
      -webkit-transition: 0.3s width 0.4s;
      transition: 0.3s width 0.4s;
    }
  
    .icon-bars::after {
      margin-top: 0px;
      background: rgb(13, 26, 38);
      content: '';
      position: absolute;
      left: 0;
      bottom: -8px;
      height: 2px;
      width: 20px;
  /*     -webkit-transition: top 0.2s ease 0.3s;
      transition: top 0.2s ease 0.3s; */
      -webkit-transition: 0.3s width 0.4s;
      transition: 0.3s width 0.4s;
    }
  
    /* Bars Shadows */
    .icon-bars.overlay {
      background: rgb(97, 114, 129);
      background: rgb(183, 199, 211);
      width: 20px;
      animation: middleBar 3s infinite 0.5s;
      -webkit-animation: middleBar 3s infinite 0.5s;
    } @keyframes middleBar {
        0% {width: 0px}
        50% {width: 20px}
        100% {width: 0px}
      } @-webkit-keyframes middleBar {
          0% {width: 0px}
          50% {width: 20px}
          100% {width: 0px}
        }
  
    .icon-bars.overlay::before {
      background: rgb(97, 114, 129);
      background: rgb(183, 199, 211);
      width: 10px;
      animation: topBar 3s infinite 0.2s;
      -webkit-animation: topBar 3s infinite 0s;
    } @keyframes topBar {
        0% {width: 0px}
        50% {width: 10px}
        100% {width: 0px}
      } @-webkit-keyframes topBar {
          0% {width: 0px}
          50% {width: 10px}
          100% {width: 0px}
        }
  
    .icon-bars.overlay::after {
      background: rgb(97, 114, 129);
      background: rgb(183, 199, 211);
      width: 15px;
      animation: bottomBar 3s infinite 1s;
      -webkit-animation: bottomBar 3s infinite 1s;
    } @keyframes bottomBar {
        0% {width: 0px}
        50% {width: 15px}
        100% {width: 0px}
      } @-webkit-keyframes bottomBar {
          0% {width: 0px}
          50% {width: 15px}
          100% {width: 0px}
        }
  
  
    /* Toggle Menu Icon */
    .menuIcon.toggle .icon-bars {
      top: 5px;
      transform: translate3d(0, 5px, 0) rotate(135deg);
      transition-delay: 0.1s;
      transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }
  
    .menuIcon.toggle .icon-bars::before {
      top: 0;
      transition-delay: 0.1s;
      opacity: 0;
    }
  
    .menuIcon.toggle .icon-bars::after {
      top: 10px;
      transform: translate3d(0, -10px, 0) rotate(-270deg);
      transition-delay: 0.1s;
      transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }
  
    .menuIcon.toggle .icon-bars.overlay {
      width: 20px;
      opacity: 0;
      -webkit-transition: all 0s ease 0s;
      transition: all 0s ease 0s;
    }
  }
  
  
  /*======================================================
                     Responsive Mobile Menu 
    ======================================================*/
  .overlay-menu {
    background: rgb(0, 190, 253);
    color: rgb(13, 26, 38);
    display: flex;
    align-items: right;
    justify-content: right;
    border-radius: 5px;
    position: absolute;
    top: 25px;
    right: 5px;
    padding: 5px;
    /* transform: translateX(-100%); */
    /* width: 100px;
    height: 115px; */
    -webkit-transition: transform 0.2s ease-out;
    transition: transform 0.2s ease-out;
    display: none;
  }
  
  .overlay-menu ul, .overlay-menu li {
    display: block;
    position: relative;
  }
  
  .overlay-menu li a {
    display: block;
    font-size: 1.8em;
    letter-spacing: 4px;
    padding: 10px 0;
    text-align: right;
    text-transform: uppercase;
    -webkit-transition: 0.2s opacity 0.2s ease-out;
    transition: 0.2s opacity 0.2s ease-out;
  }
  
  .overlay-menu li a:hover,
  .overlay-menu li a:active {
    color: rgb(28, 121, 184);
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease;
  }

  .main-navbar__menu__item__link.no-hover:hover:before {
    display: none;
  }

  .main-navbar__menu__item__link.no-hover {
    padding: 0 10px !important;
  }

  .mr-0 {
    margin-right: 0;
  }