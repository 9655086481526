BarChart {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ce0c0c;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.CustomTooltipPie {
    /* position: absolute; */
    background: white;
    color: black;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    pointer-events: none;
}

.CustomTooltipBar {
    /* position: absolute; */
    background: white;
    color: black;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    pointer-events: none;
}


